import React from "react"
import ReactPlayer from "react-player"

import RRAnalytics from "../analytics/rr_analytics"

class Video extends React.Component {
  setRef = player => {
    this.player = player
  }

  sharedTrackingData = () => ({
    currentTime: Math.round(this.player.getCurrentTime()),
    videoName: this.props.name,
    url: this.props.url,
  })

  onPlay = () =>
    RRAnalytics.trackEvent("Video", "Play", this.sharedTrackingData())
  onPause = () =>
    RRAnalytics.trackEvent("Video", "Pause", this.sharedTrackingData())

  render() {
    const { playing, style, url } = this.props
    return (
      <ReactPlayer
        controls
        onPlay={this.onPlay}
        onPause={this.onPause}
        onProgress={this.onProgress}
        playing={playing}
        ref={this.setRef}
        style={style}
        url={url}
      />
    )
  }
}

export default Video
